//Dropdown Select Start
.custom-input {
  width: 100%;
}

.css-b62m3t-container {
  width: 100%;
}
.css-16xfy0z-control,
.css-13cymwt-control {
  box-shadow: none !important;
  border: 1px solid var(--inputBorder) !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  width: 100% !important;
  min-height: 25px !important;
  height: 25px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.css-t3ipsp-control {
  height: 25px;
  box-shadow: none !important;
  border-color: var(--inputBorderHover) !important;
  border: 1px solid var(--inputBorderHover) !important;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 20px !important;
  padding: 0 5px !important;

  .css-1xc3v61-indicatorContainer {
    padding: 3px 5px 0 !important;
  }
}

.css-1nmdiqs-menu {
  font-size: 90% !important;
  z-index: 99;
}

.css-1jqq78o-placeholder {
  font-size: 90% !important;
  line-height: normal !important;
}

//Dropdown Select End
.css-t3ipsp-control,
.css-13cymwt-control {
  min-height: 25px !important;
  height: 25px !important;
}

.css-12zf3ro-control {
  &:hover {
    border-color: var(--inputBorderHover) !important;
    box-shadow: none !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    width: 100%;
    min-height: 25px !important;
    height: 25px !important;
  }
}

.css-1xc3v61-indicatorContainer {
  padding: 0 5px !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 5px !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 3px 5px 0 !important;
}

.css-1nmdiq5-menu {
  z-index: 99999 !important;
}

.css-qbdosj-Input {
  margin: 0 !important;
  padding: 0 !important;
}
.css-19bb58m{
  padding: 0 !important;
  margin: 0 !important;
}
.css-hlgwow{
  padding: 0 5px !important;
}

.css-1h01tm3-Input{
  margin: 0 !important;
}

.css-1mkvw8y{
  padding: 0 !important;
  margin: 0 !important;
}