@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");

/* // Common Styles Start */
html {
  height: 100% !important;
  margin: auto !important;
  overflow: hidden !important;
}

body {
  padding: 0;
  margin: 0;
  background-color: #0004430d;
  font-family: "Inter", sans-serif;
  font-size: 12px !important;
  color: var(--textColor);
  height: 100%;
  overflow: hidden !important;

  &.modal-open {
    .fade {
      &.modal-backdrop {
        &.show {
          &:nth-of-type(4) {
            z-index: 9999 !important;
          }
        }
      }

      &.model-center-main {
        &.modal {
          &.show {
            &:nth-of-type(5) {
              z-index: 9999 !important;
            }
          }
        }
      }
    }
  }
}

.swal2-backdrop-show {
  z-index: 9999;
}

a {
  color: var(--themeColor);
  text-decoration: none;

  &:hover {
    color: var(--darkColor);
  }
}

.section-title {
  h5 {
    margin-bottom: 0;
    color: var(--textColor);
    font-weight: 600;
    font-size: 115%;
  }
}

.date-picker-label-part {
  display: flex;
  align-items: center;

  .input-label-part {
    width: auto;
  }

  .date-picker {
    width: 100%;
  }
}

.validation-star {
  color: #e30000 !important;
  margin-left: 3px;
}

.fax-label {
  .input-label-part {
    .input-label {
      padding-left: 60px;

      @media only screen and (max-width: 1366px) {
        padding-left: 77px;
      }
    }
  }
}

.input-label-part {
  width: 100%;
  display: flex;
  align-items: center;

  .input-label {
    font-size: 100%;
    line-height: inherit;
    margin-bottom: 0px;
    display: inline-block;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-width: 125px;
    text-align: left;
    color: var(--blackColor);

    @media only screen and (max-width: 1366px) {
      font-size: 90%;
    }
  }

  .label-right-title {
    .input-label {
      text-align: right;
    }
  }
}

.label-rightalign {
  .input-label {
    text-align: left;
  }
}

.inner-input-alignment {
  .input-label-part {
    width: 40%;
  }

  &.width-50 {
    .input-label-part {
      width: 47%;
    }
  }

  &.discount-sec {
    &.small-sec {
      .input-label-part {
        width: 37%;
      }
    }
  }
}

.separation-line {
  hr {
    margin: 10px 0;
  }
}

.double-label-sec {
  .input-field-sec {
    display: flex;
    align-items: center;

    .section-title {
      margin-bottom: 0 !important;
    }

    h5 {
      font-weight: normal;
      font-size: 100%;
      min-width: 125px !important;
      color: var(--blackColor);
      margin-bottom: 0;

      @media only screen and (max-width: 1366px) {
        font-size: 90%;
      }
    }
  }
}

// custom-margin start
.m-0 {
  margin: 0 !important;
}

// Margin Left
.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

// Margin-Right
.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

// Margin-top
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

// Margin-bottom
.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mx-8 {
  margin: 0 8px;
}

// custom-margin end

// custom-padding start
.p-0 {
  padding: 0 !important;
}

// Padding Left
.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

// Padding-Right
.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

// Padding-top
.pt-0 {
  padding-top: 0;
}

.pt-1 {
  margin-top: 0.25rem;
}

.pt-2 {
  margin-top: 0.5rem;
}

.pt-3 {
  margin-top: 0.75rem;
}

.pt-4 {
  margin-top: 1rem;
}

.pt-5 {
  margin-top: 1.25rem;
}

// Padding-bottom
.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  margin-bottom: 0.25rem;
}

.pb-2 {
  margin-bottom: 0.5rem;
}

.pb-3 {
  margin-bottom: 0.75rem;
}

.pb-4 {
  margin-bottom: 1rem;
}

.pb-5 {
  margin-bottom: 1.25rem;
}

// custom-padding end
.main-tab-section {
  padding: 0 15px 0 15px;
}

.document-inner-sec {
  .main-tab-section {
    padding: 0;
  }
}

.contact-list,
.flag-list {
  .main-tab-section {
    padding: 0;
  }
}

.scope-grid {
  .card-section {
    .card-body-sec {
      .molgrid-2 {
        border-radius: 7px !important;
      }
    }
  }
}

// Common Styling Start

.main-page-layout {
  height: 100vh;
  display: flex;
  width: 100%;

  .middle-page-section {
    width: calc(100% - 220px);

    .center-content-part {
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: var(--main-bg-color);
      padding: 13px 15px;
    }
  }
}

// Common Styling End

// Tab Section Start
.tab-section {
  margin-bottom: 30px;

  .tab-header {
    padding: 6px;
    margin: 0 8px;
    border-radius: 5px;
    background: var(--whiteColor);
    box-shadow: 0px 0px 15px -8px #35436d;

    button {
      line-height: normal;
      padding: 5px 14px;
      border: none;
      background: none;
      border-radius: 7px;
      color: var(--blackColor);
      border-bottom: 0;
      margin-right: 5px;

      &.active {
        margin-bottom: -1px;
        background: linear-gradient(90deg,
            rgb(17, 60, 139) 0%,
            rgb(87, 118, 227) 100%);
        color: var(--whiteColor);

        &:hover {
          background: linear-gradient(90deg,
              rgb(17, 60, 139) 0%,
              rgb(87, 118, 227) 100%);
        }
      }

      &:hover {
        background: #1d377f12;
      }
    }
  }

  .tab-content {
    margin-top: 7px;
    padding-top: 9px;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;
    max-height: calc(100vh - 240px);
    overflow: hidden;
    overflow-y: auto;
  }
}

.repair-items-implied {
  .tab-content {
    max-height: calc(100vh - 260px);

    .tab-body-section {
      .card-section {
        margin-bottom: 0;
      }
    }

    .scrollable-table-sec {
      .card-section {
        margin-bottom: 0;
      }
    }
  }
}

.repairs-sections {
  .tab-content {
    max-height: calc(100vh - 280px);
    padding-bottom: 10px;
  }
}

// Tab Section End

.css-1u9des2-indicatorSeparator {
  padding: 0 !important;
  margin-bottom: 3px !important;
  margin-top: 3px !important;
}

.border-table-simple {
  overflow: unset;
}

.molgrid-2 {
  padding: 8px;
  background: var(--whiteColor);
  border-radius: 7px;
  // box-shadow: 0px 0px 15px -8px #35436d;
}

.table-pb-0 {
  .molgrid-2 {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 1560px) {
  body {
    font-size: 10px;
  }

  .input-label-part {
    .input-label {
      min-width: 125px;
    }
  }

  .inner-input-alignment {
    .input-label-part {
      width: 57%;
    }

    &.small-sec {
      .input-label-part {
        width: 45%;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #cfcfcf;
  /* or add it to the track */
}

/* Add a thumb */
::-webkit-scrollbar-thumb {
  background: var(--themeColor);
}

.content-desc-section {
  margin-top: 17px;
  padding: 0 8px;
}

.top-dropdown-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 8px 11px 8px;
  gap: 10px;

  .filters-group {
    width: 100%;
    gap: 30px;

    .left-section {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      min-width: 20%;

      &.departments-label {
        .input-label-part {
          .input-label-title {
            .input-label {
              min-width: 56px !important;
            }
          }
        }
      }

      .input-label-part {
        .align-items-center {
          &.mr-4 {
            margin: 0;

            .input-label {
              min-width: 45px;
              min-width: 56px;
            }
          }
        }

        .client-id {
          margin-left: 7px;
        }
      }

      .filter-label {
        margin: 0 0 0 10px;
        font-size: 13px;
        font-weight: 500;
        padding-top: 5px;
      }
    }

    .input-label-part {
      .align-items-center {
        &.mr-4 {
          margin: 0 7px 0 0;

          .input-label {
            min-width: 110px;
          }
        }
      }

      .client-id {
        margin-left: 7px;
      }
    }

    .center-section {
      min-width: 35%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .checkbox-only {
        padding-top: 5px;
      }

      .filter-label {
        padding-top: 5px;
        margin: 0 0 0 10px;
        font-size: 13px;
        font-weight: 500;
      }

      &.departments-label {
        .input-label-part {
          .input-label-title {
            .input-label {
              min-width: 90px;
            }
          }
        }
      }
    }

    .right-section {
      min-width: 30%;

      &.departments-label {
        .input-label-part {
          .input-label-title {
            min-width: 114px;
          }
        }
      }

      &.scope-label {
        .input-label-part {
          .input-label-title {
            .input-label {
              min-width: 56px !important;
            }
          }
        }
      }
    }

    .checkbox-section {
      min-width: 300px;
      margin: 0 10px 0 0;
    }
  }

  &.repair-section {
    width: 50%;
    margin-top: 0 !important;

    .input-label-part {
      .input-label-title {
        .input-label {
          min-width: 56px;
        }
      }
    }
  }
}

.administration {
  .tab-section {
    .tab-header {
      margin-left: 0;
      margin-right: 0;
    }

    .tab-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Card-section Start
.card-section {
  padding: 0;
  background: var(--whiteColor);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0px 0px 15px -8px #35436d;
  margin-bottom: 17px;

  .card-top-title-btn {
    padding: 8px 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: var(--whiteColor);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .section-title {
      font-size: 130%;
      font-weight: 600;
      color: var(--themeColor);
      margin-bottom: 0;
      width: max-content;
    }


    .right-action {
      .custom-card-header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .checkbox-part {
          width: max-content;
          margin-right: 15px;

          .input-label-title {
            .input-label {
              color: var(--blackColor);
            }
          }

        }

        .right-part {
          display: flex;
          gap: 5px;
        }
      }
    }


  }

  .card-body-sec {
    .molgrid-2 {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.card-section-left {
    .section-title {
      justify-content: flex-start;
    }

    .card-top-title-btn {
      justify-content: space-between !important;
    }
  }

  &.card-section-between {
    .card-top-title-btn {
      justify-content: space-between;
    }
  }

  &.card-button-only {
    .card-top-title-btn {
      justify-content: flex-end;
    }
  }
}

.card-body-top-remove {
  .card-section {
    .card-body-sec {
      margin-top: 0 !important;
    }
  }
}

.btn-right-sec {
  min-width: 80px !important;
  max-width: 80px !important;

  @media only screen and (max-width: 1560px) {
    min-width: 63px !important;
  }
}

// Card-section End

// Test Page UI Start
.test-page-ui {
  padding: 13px 15px;

  .list-group-item {
    table {
      thead {
        tr {
          th {
            border: 1px solid #808080;
            padding: 5px 10px;
            font-weight: normal;
            color: var(--whiteColor);
            background: var(--tableHeader);

            &:first-child {
              width: 30px;
              background: var(--tableHeader);
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 5px 10px;
            border: 1px solid #808080;
            border-top-color: #cbdaf5;
            border-bottom-color: #cbdaf5;
            text-align: left;

            &:first-child {
              width: 30px;
              background: var(--tableHeader);
              border-top-color: var(--tableHeader);
              border-bottom-color: var(--tableHeader);
            }
          }

          &:nth-child(even) {
            td {
              &:first-child {
                width: 30px;
                background: var(--tableHeader);
                border-top-color: var(--tableHeader);
                border-bottom-color: var(--tableHeader);
              }
            }
          }

          &.collapsed {
            .icon-class {
              img {
                width: 20px;
              }
            }

            &.show {
              td {
                background: #d3d7de;
              }

              .icon-class {
                background: var(--tableHeader);

                img {
                  transform: rotate(90deg);
                  -webkit-transform: rotate(90deg);
                  -moz-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  -o-transform: rotate(90deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Test Page UI End

// Add client-section Start
.new-client-sec {
  .input-label-part {
    .input-label-title {
      .input-label {
        text-align: left;
        color: var(--blackColor);
      }
    }
  }

  .input-field-sec {
    .section-title {
      h5 {
        color: var(--blackColor);
        text-align: left;
        min-width: 91px;
      }
    }

    .checkbox-label-part {
      .checkbox-part {
        .input-label-title {
          .input-label {
            color: var(--blackColor);
          }
        }
      }
    }
  }
}

.last-column-right {
  table {
    thead {
      tr {
        th {
          &:last-child {
            text-align: right !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:last-child {
            text-align: right !important;
          }
        }
      }
    }
  }
}

.second-last-column-right {
  table {
    thead {
      tr {
        th:nth-last-child(2) {
          text-align: right !important;
        }
      }
    }

    tbody {
      tr {
        td:nth-last-child(2) {
          text-align: right !important;
        }
      }
    }
  }
}

.form-card {
  background: var(--whiteColor);
  box-shadow: 0px 0px 12px -8px #35436d;
  border-radius: 7px;
  padding: 10px 0px;

  .section-title {
    margin-bottom: 5px;
  }

  .input-field-sec {
    .input-label-part {
      margin-bottom: 0;
    }
  }
}

.border-right-sec {
  background: var(--whiteColor);
  box-shadow: 0px 0px 15px -8px #35436d;
  border-radius: 7px;
  padding: 15px 12px;

  .left-form-section {
    .middle-checkbox-sec {
      display: flex;
      padding: 12px 0;
      border-bottom: 1px solid var(--inputBorder);

      .input-label-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .bottom-checkbox-sec {
      padding: 12px 0;

      .active-checkbox {
        margin-top: 12px;
      }
    }
  }
}

.border-none-sec {
  .right-form-section {
    .top-form-sec {
      .default-checkbox {
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--inputBorder);
        padding-right: 20%;
      }
    }

    .bottom-text-area-sec {
      .input-label-part {
        .text-area {
          textarea {
            min-height: 150px;
          }
        }
      }
    }
  }
}

.validation-left-0 {
  .validation-text {
    margin-left: 0;
  }
}

.border-full-sec {
  .left-form-section {
    .top-form-sec {
      border: 1px solid var(--inputBorder);
      padding: 10px;
    }
  }

  .right-form-section {
    .top-form-sec {
      border: 1px solid var(--inputBorder);
      padding: 10px;
    }
  }
}

.revers-lable {
  .input-field-sec {
    .checkbox-label-part {
      .checkbox-part {
        .checkbox {
          order: 1;
        }

        .input-label-title {
          order: 0;
          min-width: 132px;
          justify-content: flex-end;
        }
      }
    }
  }
}

.client-status-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .client-dropdown {
    display: flex;
    justify-content: flex-end;
  }
}

.repairlist-table {
  height: calc(100vh - 330px);
  overflow: hidden;
  overflow-y: auto;
}

.accordion-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.input-top-title {
  width: 100%;

  input {
    border: none;
    border: 1px solid var(--inputBorder) !important;
    border-radius: 5px !important;
    width: 100%;
    height: 28px !important;
    padding: 0 8px;
    font-size: 90%;

    &:focus-visible,
    &:focus {
      box-shadow: none !important;
      border: 1px solid var(--inputBorder) !important;
      outline-offset: 1px !important;
    }

    &:focus-visible {
      outline: none;
      border-color: var(--inputBorder) !important;
    }
  }

  h5 {
    font-size: 115%;
    font-weight: 600;
    text-align: center;
  }
}

.phone-input-component {
  button {
    max-height: 28px;
  }

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.input-label-title {
  margin-bottom: 0px;

  @media only screen and (max-width: 1366px) {
    font-size: 90%;
  }
}

.checkbox-part {
  .input-label-title {
    margin-bottom: 0 !important;
    margin-top: 1px;
  }
}

.no-label-required-field {
  .validation-star {
    display: none;
  }
}

//only chechbox withoutlabel
.checkbox-only {
  margin-left: 5px;

  .checkbox-part {
    flex-direction: row-reverse;
    margin-right: -10px;
  }

  .input-label-title {
    display: none;
  }
}

@media only screen and (max-width: 1560px) {
  .center-section {
    .filter-label {
      font-size: 10px !important;
    }

    &.departments-label {
      .input-label-part {
        .input-label-title {
          .input-label {
            min-width: 90px !important;
          }
        }
      }
    }
  }

  .right-section {
    &.departments-label {
      .input-label-part {
        .input-label-title {
          .input-label {
            min-width: 114px;
          }
        }
      }
    }
  }

  .left-section {
    .filter-label {
      font-size: 10px !important;
    }

    &.departments-label {
      .input-label-part {
        .input-label-title {
          .input-label {
            min-width: 42px !important;
          }
        }
      }
    }
  }
}

.scopes-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 20px;
    width: 96%;
    height: calc(100vh - 190px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 170px);
    }

    img {
      width: 500px;
    }
  }
}

.repairs-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 0px;
    width: 97%;
    height: calc(100vh - 265px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 265px);
    }

    img {
      width: 500px;
    }
  }
}

.inventory-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 20px;
    width: 96%;
    height: calc(100vh - 120px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 115px);
    }

    img {
      width: 500px;
    }
  }
}

.po-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 20px;
    width: 96%;
    height: calc(100vh - 285px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 285px);
    }

    img {
      width: 500px;
    }
  }
}

.departments-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 20px;
    width: 96%;
    height: calc(100vh - 175px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 150px);
    }

    img {
      width: 500px;
    }
  }
}

.client-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 20px;
    width: 96%;
    height: calc(100vh - 245px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 245px);
    }

    img {
      width: 500px;
    }
  }
}

.user-nodatafound {
  .data-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
    position: relative;
    border: 2px dashed #e0e0e0;
    margin: 20px;
    width: 96%;
    height: calc(100vh - 280px);
    border-radius: 8px;

    @media only screen and (max-width: 1560px) {
      width: 95%;
      height: calc(100vh - 280px);
    }

    img {
      width: 500px;
    }
  }
}

// Add client-section End
@media only screen and (max-width: 1820px) {
  .client-section {
    .save-copy-delete-section {
      padding: 3px;

      .default-btn {
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media only screen and (max-width: 1680px) {
  .pricing-category {
    .double-label-sec {
      .input-field-sec {
        h5 {
          min-width: 102px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1650px) {
  .client-section {
    .save-copy-delete-section {
      padding: 2px;

      .default-btn {
        width: 24px;
        height: 24px;
      }
    }
  }
}

// Toolbar buttons End

.repairs-items-list {
  padding: 0 13px;

  .molgrid-2 {
    max-height: calc(100vh - 225px);
    overflow: hidden;
    overflow-y: auto;
  }

  thead {
    tr {
      th {

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      td {

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          text-align: center;
        }
      }
    }
  }
}

.productIds-table {
  .border-table-simple {
    thead {
      tr {
        th {

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }

  .molgrid-2 {
    max-height: calc(100vh - 386px);
    overflow: hidden;
    overflow-y: auto;
    box-shadow: none;
  }
}

.grid-pre-tag {
  font-family: "Inter", sans-serif !important;
  font-size: 100% !important;
  margin: 2px 0 2px 0 !important;
}

.status-spacing {
  .input-label-part {
    .input-label {
      min-width: 50px;
    }
  }
}

.priority-spacing {
  .input-label-part {
    .input-label {
      min-width: 60px;
    }
  }
}

.type-spacing {
  .input-label-part {
    .input-label {
      min-width: 50px;
    }
  }
}

.from-spacing {
  .input-label-part {
    .input-label {
      min-width: 50px;
    }
  }
}

.to-spacing {
  .input-label-part {
    .input-label {
      min-width: 60px;
    }
  }
}

.scope-filters {
  .scope-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    align-items: center;

    .input-label-title {
      .input-label {
        min-width: 100px;
      }
    }
  }

  .scope-filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 0;

    .scope-select {
      margin-bottom: 12px;
      width: 70%;

      .search-part {
        margin-right: 0;
      }

      .input-label-title {
        .input-label {
          min-width: 50px;
        }
      }

      .custom-input {
        width: 100%;
      }
    }

    .scope-checkbox {
      margin-bottom: 12px;
      white-space: nowrap;
    }
  }

  .search-criteria {
    .input-label-title {
      .input-label {
        min-width: 50px;
      }
    }
  }
}

.accordion-button {
  padding: 3px 10px !important;
  font-size: 12px;
  font-weight: 600;
}

.accordion-button::after {
  background-size: 15px;
  background-position: center;
}

.accordion-body {
  padding: 10px;
}

.radio-center {
  .input-label-part {
    justify-content: center;
  }
}

.scope-filter-btn {
  .btn {
    height: 30px !important;
    padding: 0 !important;
  }
}

.type-min-width {
  .input-label-part {
    .input-label {
      min-width: 60px !important;
    }
  }
}

.serial-min-width {
  .input-label-part {
    .input-label {
      min-width: 60px !important;
    }
  }
}

.upc-min-width {
  .input-label-part {
    .input-label {
      min-width: 60px !important;
    }
  }
}

.comment-min-width {
  .input-field-sec {
    .input-label-part {
      .input-label {
        min-width: 70px !important;
      }
    }
  }
}

@media only screen and (max-width: 1560px) {
  .search-criteria-small {
    .input-label-title {
      min-width: 25px !important;

      .input-label {
        min-width: 25px !important;
      }
    }
  }

  .comment-min-width {
    .input-field-sec {
      .input-label-part {
        .input-label {
          min-width: 70px !important;
        }
      }
    }
  }

  .scope-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;

    .input-label-part {
      .input-label-title {
        .input-label {
          min-width: 50px !important;
        }
      }
    }
  }

  .scope-select {
    margin-bottom: 20px;

    .input-label-title {
      .input-label {
        min-width: 60px !important;
      }
    }
  }
}

.scope-main-tab-label {
  .input-label-part {
    .input-label {
      min-width: 110px;
    }
  }
}

.scope-container {
  .scrollable-table-sec {
    padding: 0 10px;
    height: calc(100vh - 140px);

    .card-section {
      margin-bottom: 0;
    }
  }

  .tab-section {
    .tab-content {
      height: calc(100vh - 235px);

      @media only screen and (max-width: 1560px) {
        height: calc(100vh - 235px);
      }

      .card-section-leftcard-button-only {
        margin-bottom: 10px;
      }
    }
  }
}

.lot-number {
  .input-label-part {
    .input-label {
      min-width: 105px;
    }
  }
}

.technician {
  .input-label-part {
    .input-label {
      min-width: 105px;
    }
  }
}

.task-list-grid {
  max-height: calc(100vh - 240px);
  overflow: hidden;
  overflow-y: auto;
}

.instrument-list-grid {
  max-height: calc(100vh - 140px);
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 1560px) {
  .scope-container {
    .scrollable-table-sec {
      height: calc(100vh - 110px);
    }
  }
}

.client-section {
  height: calc(100% - 10px);

  .save-copy-delete-section {
    display: flex;
    border-radius: 5px;
    align-items: center;
    background: var(--whiteColor);
    width: fit-content;
    box-shadow: 0px 0px 15px -8px #35436d;
    margin-top: 0;
    padding: 0px 0px;

    .default-btn {
      padding: 5px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid transparent;
      margin: 5px;
      transition: 0.5s all;
      min-width: 30px !important;
      max-width: 30px !important;

      .component-iconify {
        color: var(--themeColor);
        transition: 0.5s all;
        width: 25px !important;
        height: 25px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;

        svg {
          width: 22px !important;
          height: 22px !important;
        }
      }

      &:hover {
        background: var(--themeColor);
        border-radius: 5px;

        .component-iconify {
          color: var(--whiteColor);
        }
      }

      img {
        width: 20px;
        height: auto;
        margin: 0;
        filter: unset;
        -webkit-filter: unset;
      }

      &.border-right {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          background: var(--inputBorder);
          right: -6.5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .divider {
      margin: 0 2px;
      font-size: 18px;
    }
  }
}

.subgroup-tab-content {

  .right-table-sec,
  .left-table-sec {
    .card-section {
      margin-bottom: 0;
    }

    .molgrid-2 {
      max-height: calc(100vh - 304px);
      overflow: hidden;
      overflow-y: auto;
      margin-top: 10px;
      padding-top: 0;
      box-shadow: none;
    }

    .card-top-title-btn {
      justify-content: space-between !important;
    }
  }
}

.scope-list-grid-sec {
  .molgrid-2 {
    max-height: calc(100vh - 270px);
    overflow: hidden;
    overflow-y: auto;
  }

  &.accordion-open {
    .molgrid-2 {
      max-height: calc(100vh - 377px);
    }
  }
}

.tab-repair-items {
  max-height: calc(100vh - 270px);
}

.instrument-section-top {
  .molgrid-2 {
    margin: 0 8px;
  }

  .form-card {
    margin: 0 -8px;
  }
}

.manufacturer {
  padding-right: 0 !important;
}

.reporting-group {
  padding-left: 0 !important;
}

.small-grid-list {
  max-height: calc(100vh - 395px);
  overflow: hidden;
  overflow-y: auto;
}

.dashboard-section-scope {
  .molgrid-2 {
    max-height: calc(100% - 15px);
    overflow: hidden;
    overflow-y: auto;
  }
}

.min-height {
  min-height: 33px !important;
}

.document-label {
  .input-label-part {
    .input-label-title {
      .input-label {
        min-width: 70px;

        @media only screen and (max-width: 1560px) {
          min-width: 90px;
        }
      }
    }
  }
}

.sidebar-model {
  &.custom-bg-color {
    .add-client-model {
      .right-model-section {
        .model-content {
          .model-body {
            .top-form-sec {
              .new-client-sec {
                overflow: hidden !important;
                max-height: calc(100vh - 40px) !important;
              }
            }
          }
        }
      }
    }
  }
}

.purchase-section {
  .checkbox-part {
    .input-label {
      min-width: 100px;
    }
  }
}

.add-pricing-model-label {
  .input-label-part {
    .input-label-title {
      .input-label {
        min-width: 175px !important;
      }
    }
  }

  .validation-text {
    margin-left: 175px !important;
  }
}

.user-security {
  .validation-text {
    margin-left: 130px !important;
  }
}

.import-pricing-form-field {
  .validation-text {
    margin-left: 130px !important;
  }
}

.active-label {
  .input-field-sec {
    .section-title {
      h5 {
        min-width: 55px !important;
      }
    }
  }
}

.count-label {
  .input-field-sec {
    .section-title {
      h5 {
        min-width: 140px !important;
      }
    }
  }
}

.no-repair-label {
  .input-field-sec {
    .section-title {
      h5 {
        min-width: 135px !important;
      }
    }
  }
}

.pick-list-label {
  .input-field-sec {
    .section-title {
      h5 {
        min-width: 95px !important;
      }
    }
  }
}

.permission-label {
  margin-bottom: 0 !important;
  gap: 15px !important;

  .input-label-part {
    margin-bottom: 0 !important;

    .input-label-title {
      label {
        min-width: 190px !important;
      }
    }
  }
}

.service-location-sec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  .input-label-part {
    margin-bottom: 0 !important;
  }
}

.scope-days {
  .input-label-part {
    .input-label-title {
      .input-label {
        min-width: 90px !important;
      }
    }
  }

  .validation-text {
    margin-left: 110px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.scope-users {
  .input-label-part {
    .input-label-title {
      .input-label {
        min-width: 110px !important;
      }
    }
  }

  .validation-text {
    margin-left: 110px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.contact-sec {
  .input-label-part {
    .input-label {
      min-width: 100px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 100px !important;
      }
    }
  }

  .validation-text {
    margin-left: 100px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.column-field-sec {
  .input-label-part {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.flag-section {
  .input-label-part {
    .input-label {
      min-width: 105px;
      margin-bottom: 2px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 105px !important;
      }
    }
  }

  .validation-text {
    margin-left: 105px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.add-client-sec {
  .input-label-part {
    .input-label {
      min-width: 115px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 115px !important;
      }
    }
  }

  .validation-text {
    margin-left: 115px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.css-1p3m7a8-multiValue {
  margin: 1px 2px !important;
}

.css-wsp0cs-MultiValueGeneric {
  padding: 1px 3px !important;
}

.client-left-sec {
  .input-label-part {
    .input-label {
      min-width: 112px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 112px !important;
      }
    }
  }

  .validation-text {
    margin-left: 112px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.client-right-sec {
  .input-label-part {
    .input-label {
      min-width: 115px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 115px !important;
      }
    }
  }

  .validation-text {
    margin-left: 115px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.department-main-left {
  .input-label-part {
    .input-label {
      min-width: 110px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 110px !important;
      }
    }
  }

  .validation-text {
    margin-left: 110px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.address-main-sec {
  .input-label-part {
    .input-label {
      min-width: 98px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 98px !important;
      }
    }
  }

  .validation-text {
    margin-left: 98px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.max-charge-sec {
  .input-label-part {
    .input-label {
      min-width: 117px;
    }
  }

  .validation-text {
    margin-left: 117px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.add-dept-sec {
  .input-label-part {
    .input-label {
      min-width: 107px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 107px !important;
      }
    }
  }

  .validation-text {
    margin-left: 107px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.add-dept-sec-last {
  .input-label-part {
    .input-label {
      min-width: 95px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 95px !important;
      }
    }
  }

  .validation-text {
    margin-left: 95px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.inventory-title-sec {
  .input-label-part {
    .input-label {
      min-width: 67px;
    }
  }

  .double-label-sec {
    .input-field-sec {
      h5 {
        min-width: 67px !important;
      }
    }
  }

  .validation-text {
    margin-left: 67px;
  }

  .validation-left-0 {
    .validation-text {
      margin-left: 0;
    }
  }
}

.security-tab {
  .tab-content {
    overflow: unset !important;
    max-height: unset !important;
  }

  .tab-body-section {
    .tab-content {
      max-height: calc(100vh - 295px) !important;
      overflow: hidden !important;
      overflow-y: auto !important;
    }
  }
}

.user-sec {
  max-height: calc(100vh - 240px) !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.instrument-item-label{
  .input-label{
    min-width: 90px;
  }
}

@media only screen and (max-width: 1560px) {
  .instrument-item-label{
    .input-label{
      min-width: 80px;
    }
  }
}

.top-filter-sec-scope{
  .form-card {
    height: 100%;
    display: flex;
    align-items: center;
    .col-md-6{
      width: 100%;
      .input-label-part{
        justify-content: space-around;
      }
    }
  }
}