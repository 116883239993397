.two-factor-authentication-section {
  .Login-main-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: #f9faff;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background: url(../../assets/images/login-shap.png);
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;
      width: 130px;
      bottom: 20px;
      left: 0;
      z-index: 0;
    }

    &:after {
      content: "";
      position: absolute;
      background: url(../../assets/images/login-shap.png);
      background-repeat: no-repeat;
      background-size: contain;
      width: 250px;
      height: 500px;
      bottom: -190px;
      right: -20px;
      z-index: 0;
      transform: rotate(210deg);
    }

    .login-box-part {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      height: 80%;
      background: var(--whiteColor);
      box-shadow: inset 0 0 15px hsla(0, 0%, 100%, 0.451),
        inset 0 0 15px rgba(79, 144, 255, 0.169),
        0 0 30px -10px rgba(128, 160, 215, 0.431);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      z-index: 1;
      overflow: hidden;

      .left-sec {
        padding: 50px 50px 50px 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/images/background.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }

      .center-sec {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .inner-box {
          background: var(--whiteColor);
          box-shadow: inset 0 0 15px hsla(0, 0%, 100%, 0.451),
            inset 0 0 15px rgba(79, 144, 255, 0.169),
            0 0 30px -10px rgba(128, 160, 215, 0.431);
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          width: 450px;
          height: 100%;
          padding: 10px;

          .card-icon-check {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -50px;

            .component-iconify {
              background-color: white;
              color: #0fc00f;
              border-radius: 50%;
            }
          }

          .card-title {
            font-size: 25px;
            font-weight: 700;
            color: var(--themeColor);
            text-align: center;
            margin: 5px 0 10px;
          }

          .card-sub-title {
            font-size: 14px;
            color: var(--blackColor);
            text-align: center;
            margin: 0 0 10px;
          }

          .input-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            .input-box {
              border: 1px solid #cbcbcb;
              width: 40px;
              height: 40px;
              border-radius: 7px;
              text-align: center;
              outline-color: var(--themeColor);
              -moz-appearance: textfield;
              /* Firefox */
              appearance: textfield;
              /* Modern browsers */
              font-size: 16px;
            }
          }

          .submit-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0 10px;
          }
        }
      }
    }
  }
}