.unauthorized-section{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 79px);
  .left-text{
    padding-right: 100px;
    text-align: left;
    h1{
      font-size: 100px;
      margin-bottom: 15px;
      font-weight: 600;
      color: var(--themeColor);
    }
    h4{
      font-size: 2.7rem;
      margin-bottom: 15px;
      font-weight: 600;
      color: var(--blackColor);
    }
    p{
      font-size: 1.8rem;
      margin-bottom: 0;
      color: var(--blackColor);
    }
  }
  .right-img{
    text-align: left;
    img{
      margin: 0 auto;
    }
  }
}