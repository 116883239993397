.sidebar-section {
  background: var(--whiteColor);
  position: relative;
  width: 220px;
  height: 100vh;
  overflow: hidden;
  box-shadow: 0px 0px 15px -8px #35436d;

  .sidebar {
    .main-menus {
      .sidebar-toggler {
        display: none;
      }

      .sidebar-brand {
        display: block;
        padding: 13px 0px;
        margin: 0 10px;
        text-align: center;
        border-bottom: 1px solid var(--themeColor);

        img {
          width: 90%;
          margin: a auto;
        }

        h2 {
          font-size: 23px;
          font-weight: 800;
          color: var(--themeColor) !important;
          margin-top: 7px;
        }
      }

      .sidebar-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        ul {
          list-style-type: none;
          padding-left: 0;

          &.sidebar-menu-list {
            padding: 0 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            height: calc(100vh - 185px);
            overflow: hidden;
            overflow-y: auto;

            li {
              margin-bottom: 3px;
              &.menu-item {
                a {
                  font-size: 90%;
                  color: var(--blackColor);
                  padding: 8px 15px;
                  display: flex;
                  align-items: center;
                  border-radius: 5px;
                  min-height: 37px;

                  i {
                    font-size: 14px;

                    &.roted-80 {
                      transform: rotate(90deg);
                      -webkit-transform: rotate(90deg);
                      -moz-transform: rotate(90deg);
                      -ms-transform: rotate(90deg);
                      -o-transform: rotate(90deg);
                    }

                    &.roted--80 {
                      transform: rotate(360deg);
                      -webkit-transform: rotate(360deg);
                      -moz-transform: rotate(360deg);
                      -ms-transform: rotate(360deg);
                      -o-transform: rotate(360deg);
                    }

                    &.border-i {
                      height: 12px;
                      width: 12px;
                      padding: 8px !important;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border: 1px solid var(--blackColor);
                    }

                    &.scopes-icon {
                      content: "";
                      background: url(../../../assets/images/crosshair.png);
                      height: 14px;
                      width: 14px;
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: contain;
                    }

                    &.stamp-icon {
                      content: "";
                      background: url(../../../assets/images/stamp.png);
                      height: 14px;
                      width: 14px;
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: contain;
                    }

                    &.hammer-rotation {
                      transform: rotate(50deg);
                      -webkit-transform: rotate(50deg);
                      -moz-transform: rotate(50deg);
                      -ms-transform: rotate(50deg);
                      -o-transform: rotate(50deg);
                    }
                  }

                  span {
                    padding-left: 15px;
                    font-size: 107%;
                    font-weight: 500;
                    line-height: normal;
                  }

                  &.menu-arrow {
                    position: relative;

                    &::before {
                      content: "";
                      position: absolute;
                      height: 15px;
                      width: 15px;
                      background: url(../../../assets/images/arrow.png);
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: contain;
                      right: 15px;
                      top: 55%;
                      transform: translateY(-50%) rotate(270deg);
                      -webkit-transform: translateY(-50%) rotate(270deg);
                      -moz-transform: translateY(-50%) rotate(270deg);
                      -ms-transform: translateY(-50%) rotate(270deg);
                      -o-transform: translateY(-50%) rotate(270deg);
                      transition: 0.5s all;
                      -webkit-transition: 0.5s all;
                      -moz-transition: 0.5s all;
                      -ms-transition: 0.5s all;
                      -o-transition: 0.5s all;
                    }
                  }
                }

                &.active-menu,
                &:hover {
                  a {
                    background: linear-gradient(
                      90deg,
                      rgba(17, 60, 139, 1) 0%,
                      rgba(87, 118, 227, 1) 100%
                    );
                    color: var(--whiteColor);

                    &.menu-arrow {
                      &::before {
                        filter: brightness(100);
                        -webkit-filter: brightness(100);
                      }
                    }

                    i {
                      &.scopes-icon {
                        content: "";
                        background: url(../../../assets/images/crosshair-white.png);
                        height: 14px;
                        width: 14px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                      }

                      &.border-i {
                        border-color: var(--whiteColor);
                      }

                      &.stamp-icon {
                        content: "";
                        background: url(../../../assets/images/stamp-white.png);
                        height: 14px;
                        width: 14px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                      }
                    }
                  }
                }

                &.active-menu {
                  a {
                    &::before {
                      transform: translateY(-50%) rotate(360deg);
                      -webkit-transform: translateY(-50%) rotate(360deg);
                      -moz-transform: translateY(-50%) rotate(360deg);
                      -ms-transform: translateY(-50%) rotate(360deg);
                      -o-transform: translateY(-50%) rotate(360deg);
                    }
                  }
                  .sidebar-dropdown {
                    height: auto;
                    opacity: 1;
                    .dropdown-menus {
                      a {
                        color: var(--blackColor);

                        &.active-sub-menu,
                        &:hover {
                          color: var(--whiteColor);
                        }
                      }
                    }
                  }
                }

                ul {
                  &.sidebar-dropdown {
                    padding-left: 30px;
                    height: 0;
                    overflow: hidden;
                    transition: 0.5s all;
                    -webkit-transition: 0.5s all;
                    -moz-transition: 0.5s all;
                    -ms-transition: 0.5s all;
                    -o-transition: 0.5s all;
                    opacity: 0;

                    li {
                      a {
                        background: none;
                        width: 100%;
                        display: block;
                        opacity: 0.8;

                        &::before {
                          display: none;
                        }
                      }

                      &:hover {
                        a {
                          color: var(--themeColor);
                          opacity: 1;
                        }
                      }

                      &:last-child {
                        a {
                          padding-top: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottom-menu {
          border-top: 1px solid #aeb6c3;
        }
      }
    }
  }
}

.style-0{
  padding: 0;
  border: none;
  outline: none;
  width: 100%;
  box-shadow: none;
  background: transparent;
}

@media only screen and (max-width: 1560px) {
  .sidebar-section {
    .sidebar {
      .main-menus {
        .sidebar-menu {
          ul {
            &.sidebar-menu-list {
              li {
                &.menu-item {
                  a {
                    padding: 5px 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
