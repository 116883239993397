@mixin error-styling {
  border: 1px solid red;
}

.input-field {
  border: none;
  border: 1px solid var(--inputBorder) !important;
  border-radius: 5px !important;
  width: 100%;
  height: 25px;
  padding: 0 8px;
  font-size: 90%;

  &:focus-visible,
  &:focus {

    box-shadow: none !important;
    border: 1px solid var(--inputBorder) !important;
    outline-offset: 1px !important;
  }

  &:focus-visible {
    outline: none;
    border-color: var(--inputBorder) !important;
  }
}

// style for error added by nigam need review
input.error {
  @include error-styling;
}

input.error:focus {
  outline: none;
  box-shadow: 0 0 5px red;
}

.validation-text {
  color: #e30000;
  font-size: 11px;
  font-weight: 500;
  margin-left: 125px;
  margin-top: -10px;
}

.validation-star {
  color: #e30000;
  margin-left: 3px;
}

.checkbox-label-part {
  .validation-star {
    display: none;
  }
}

form {
  width: 100%;
}

@media only screen and (max-width: 1560px) {
  .validation-text{
    font-size: 10px;
  }
  .input-field{
    height: 30px;
    padding: 0 5px;
  }
}