.reset-pass-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #f9faff;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background: url(../../assets/images/login-shap.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 130px;
    bottom: 20px;
    left: 0;
    z-index: 0;
  }

  &:after {
    content: "";
    position: absolute;
    background: url(../../assets/images/login-shap.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 250px;
    height: 500px;
    bottom: -190px;
    right: -20px;
    z-index: 0;
    transform: rotate(210deg);
  }

  .login-box-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 80%;
    background: var(--whiteColor);
    box-shadow: inset 0 0 15px hsla(0, 0%, 100%, .451), inset 0 0 15px rgba(79, 144, 255, .169), 0 0 30px -10px rgba(128, 160, 215, .431);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    z-index: 1;
    overflow: hidden;

    .left-sec {
      width: 20%;
      padding: 50px;
      position: relative;

      .reset-pass-fields {
        background: var(--whiteColor);
        box-shadow: inset 0 0 15px hsla(0, 0%, 100%, .451), inset 0 0 15px rgba(79, 144, 255, .169), 0 0 30px -10px rgba(128, 160, 215, .431);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        width: 450px;
        height: 100%;
        padding: 50px 30px;
        position: relative;
        top: 50%;
        left: 20px;
        h6{
          text-align: center;
          font-weight: 600;
          color: #111;
        }
        .reset-form-section {
          .input-label-part {
            flex-direction: column;
            align-items: flex-start;
          }
          .validation-text{
            margin-left: 0;
          }
        }
        .button-part {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

        }

      }
    }

    .right-sec {
      padding: 50px 0px 50px 50px;
      width: 80%;
      height: 100%;
      background: url(../../assets/images/background.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1560px) {
        padding: 50px 0px 50px 150px;
        width: 70%;
      }
      img{
          @media only screen and (max-width: 1200px) {
            width: 65%;
        }
      }
    }
  }
}