:root {
  --themeColor: #1d377f;
  --themeColor2:#89a9d9;
  --whiteColor: #ffffff;
  --blackColor:#000;
  --textColor:#71817E;
  --grayColor:#868686;
  --grayColorLight:#B6C8C9;
  --grayColorDoubleLight: #ededed;
  --darkGray:#5c5c5c;
  --whiteColor: #ffffff;
  --inputBorder: #ccc;
  --inputBorderHover:#101010;
  --themeColorLight: rgba(29, 55, 127, 0.502);
  --tableHeaderBg:#8fa9d9;
  --lightBg-Color: #eff6fd;
  --mainBgColor:#f5f9fc;
  --tableHeader:#8fa9d9;
  --tableRows:#dfe9f0;
--main-bg-color:#f6f7fb;

  --primaryColor:#4361ee;
  --secondaryColor:#805dca;
  --dangerColor:#e7515a;
  --warningColor:#e2a03f;
  --infoColor:#2196f3;
  --successColor: #00C32B;
  
  --lightYellow:#fff8dc;
  --lightGray:#f0f0f0;
  
  --primaryColorLight:#4463ee33;
  --secondaryColorLight:#805dcb33;
  --dangerColorLight:#e7505a33;
  --warningColorLight:#e2a24033;
  --infoColorLight:#2094f333;

  --box-shadow:0px 16px 16px -1px rgba(10,22,70,.1),0px 0px 1px 0px rgba(10,22,70,.06);
  --box-borderColor:#f0f2f6;

  
  // Bg-Gradient Color Start
  --theme-bg-g:linear-gradient(to right, #005C97 0%, #363795  51%, #005C97  100%);
  --dark-bg-g:linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  --orange-yellow-bg-g:linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  --light-red-bg-g:linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a, #FBB03B);
  --danger-bg-g:linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
  --warning-bg-g:linear-gradient(to right, #25aae1, #4476eb, #04befe, #3f86ed);
  --info-bg-g:linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
  // Bg-Gradient Color End
}
