.btn {
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
  min-width: 80px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  font-size: 100%;
  background-size: 180% 100%;
  transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  font-weight: 500;
  color: var(--whiteColor);
  height: 25px;

  @media only screen and (max-width: 1366px) {
    font-size: 90%;
  }

  &:hover {
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    color: var(--whiteColor);
  }

  img {
    width: 14px;
    margin-right: 8px;
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }

  &.btn blue-btn  {
    background: #1b54c2;

    &:hover {
      background: var(--primaryColor);
    }
  }

  &.dark-btn {
    background: #2d3641bf;

    &:hover {
      background: #3f4a58bf;
    }
  }

  &.secondary-btn {
    min-width: 80px;
    background-image: linear-gradient(90deg, #3b3b3b 0%, #b4b4b4 100%);

    &:hover {
      background-image: linear-gradient(90deg, #1e1e1e 0%, #868686 100%);
    }
  }

  &.orange-yellow-btn {
    background-image: var(--orange-yellow-bg-g);
    box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
  }

  &.light-red-btn {
    background-image: var(--light-red-bg-g);
    box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
  }

  &.danger-btn {
    background-image: var(--danger-bg-g);
    box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
  }

  &.warning-btn {
    background-image: var(--warning-bg-g);
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
  }

  &.info-btn {
    background-image: var(--info-bg-g);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  }

  &.blue-btn {
    min-width: 80px;
    background-image: linear-gradient(90deg, rgb(17, 60, 139) 0%, rgb(87, 118, 227) 100%);
    transition: 0.5s all;

    &:hover {
      background-image: linear-gradient(90deg, rgb(12, 41, 95) 0%, rgb(71, 96, 184) 100%);
    }
  }

  &.disable-btn {
    min-width: 80px;
    color: #fff;
    cursor: not-allowed !important;
    pointer-events: unset;
  }
}

.button-loading-part {
  content: "";
  width: 16px;
  height: 16px;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}