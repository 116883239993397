.header-banner-section {
  height: 70px;
  border-bottom: 1px solid var(--grayColorLight);
  background: linear-gradient(90deg, rgb(17, 60, 139) 0%, rgb(87, 118, 227) 100%);
  padding: 5px 15px;
  display: flex;
  align-items: center;



  .top-user-name {
    width: 350px;
    h6 {
      margin: 0;
      color: var(--whiteColor);
      font-weight: 400;
      span {
        font-weight: bold;
      }
    }
  }

  .center-logo-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 600px);

    img {
      height: auto;
      max-width: 230px;
    }
  }

  .right-dropdown {

    .service-location-sec {
      width: 350px;
      .input-label-part {
        margin-bottom: 0 !important;
        .input-label-title {
          margin-right: 5px;

          .input-label {
            min-width: 110px;
            color: var(--whiteColor);
          }
        }
      }
    }
  }

}