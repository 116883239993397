.swal2-styled {
  box-shadow: none;
  background-size: 180% 100% !important;
  transition: all .4s ease-in-out !important;
  -o-transition: all .4s ease-in-out !important;
  -webkit-transition: all .4s ease-in-out !important;
  -moz-transition: all .4s ease-in-out !important;
  -ms-transition: all .4s ease-in-out !important;
  min-width: 120px !important;
  height: 44px !important;
  &:hover {
    background-image: unset !important;
  }

  &.swal2-confirm {
    color: var(--whiteColor) !important;
    background-image: #1b54c2 !important;
    box-shadow: 0 10px 20px -10px #1b54c2 !important;

    &:hover {
      background: #113275 !important;
      background-position: 100% 0 !important;
      transition: all .4s ease-in-out !important;
      -o-transition: all .4s ease-in-out !important;
      -webkit-transition: all .4s ease-in-out !important;
      -moz-transition: all .4s ease-in-out !important;
      -ms-transition: all .4s ease-in-out !important;
      box-shadow: 0 10px 20px -10px #11327499;
    }
  }

  &.swal2-cancel {
    color: var(--whiteColor) !important;
    background: #586371 !important;
    box-shadow: 0 10px 20px -10px #586371 !important;

    &:hover {
      background: #29323c !important;
      background-position: 100% 0 !important;
      transition: all .4s ease-in-out !important;
      -o-transition: all .4s ease-in-out !important;
      -webkit-transition: all .4s ease-in-out !important;
      -moz-transition: all .4s ease-in-out !important;
      -ms-transition: all .4s ease-in-out !important;
      box-shadow: 0 10px 20px -10px #29323c !important;

    }
  }
}

.swal2-title{
  font-size: 18px;
}

.swal2-html-container{
  .input-confirm{
    .inputs{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      label{
        font-size: 16px;
        margin: 0 25px 0 0;
      }
      input{
        margin: 0;
        padding: 20px;
        height: 0;
        min-width: 320px;
      }
    }
  }
}

.swal2-container{
  .swal2-input{
    font-size: 15px;
  }
}